import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "../App";
import { ErrorPage } from "../features/ErrorPage";
import SubscriptionPage from "../modules/subscription/SubscriptionPage";
import PaymentSuccess from "../modules/subscription/files/Success";
import PaymentCancel from "../modules/subscription/files/Paymentcancel";

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          {/* Redirect to Subscription Page with a Default UUID */}
          <Route path="/" element={<Navigate to="/info/ecfb7fcf-cd94-4997-a655-358aeffe13f0" />} />

          {/* Public Routes */}
          <Route path="error/*" element={<ErrorPage />} />
          <Route path="info/:id" element={<SubscriptionPage />} />
          <Route path="payment-success/*" element={<PaymentSuccess />} />
          <Route path="payment-cancel/*" element={<PaymentCancel />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;

import React, { Suspense, useEffect } from "react";
import { SplashScreen } from "./features/SplashScreen";
import { Outlet } from "react-router-dom";

const App: React.FC = () => {


  return (
    <Suspense fallback={<SplashScreen />}>
 
          <Outlet />

    </Suspense>
  );
};

export default App;

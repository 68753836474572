import React from "react";
import { useParams } from "react-router-dom";
import ListPlans from "./files/ListSubscription";

const SubscriptionPage = () => {
  const { id } = useParams(); // Access the UUID from the URL

  return (
    <div>
 
      <ListPlans /> 
    </div>
  );
};

export default SubscriptionPage;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Modal } from "react-bootstrap";
import { ProfileDetails, unsubscribePlan } from "../core/_requests";

interface PlanUnsubscribeProps {
    showFinalModal?: boolean;
}

const PlanUnsubscribe: React.FC<PlanUnsubscribeProps> = ({ showFinalModal = false }) => {
    const [showFinalConfirmationModal, setShowFinalConfirmationModal] = useState(showFinalModal);

    const handleCloseFinalConfirmationModal = () => setShowFinalConfirmationModal(false);

    console.log("*********************************************************************");

    console.log("showModal", showFinalModal);
    console.log("*********************************************************************");

    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const user_id = id;
    const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
    const [showFeaturesModal, setShowFeaturesModal] = useState(false);
    const [showManageDishesModal, setShowManageDishesModal] = useState(false);
    const [listPlan, setListPlan] = useState<any>(null);
    const [listPlanDish, setListPlanDish] = useState<any>(null);

    useEffect(() => {
        const fetchProfileDetails = async () => {
            try {
                const res = await ProfileDetails(user_id);
                setListPlan(res?.data?.user_plan_subscriptions[0]);
                setListPlanDish(res?.data);
            } catch (error) {
                console.error("Error fetching profile details:", error);
            }
        };

        fetchProfileDetails();
    }, [user_id]);

    const handleShowUnsubscribeModal = () => {
        setShowUnsubscribeModal(true);
    };

    const handleCloseUnsubscribeModal = () => {
        setShowUnsubscribeModal(false);
        setShowManageDishesModal(false);
        setShowFeaturesModal(false);
        setShowFinalConfirmationModal(false); // Default to false if undefined


    };

    const handleConfirmUnsubscribe = () => {
        setShowUnsubscribeModal(false);
        setShowFeaturesModal(true);
    };

    const handleProceedWithFeaturesWarning = () => {
        setShowFeaturesModal(false);

        // Check if the user is allowed to unsubscribe based on the plan dishes
        if (listPlanDish?.isPlanDish === false) {
            setShowManageDishesModal(true); // Show manage dishes modal
        } else {
            setShowFinalConfirmationModal(true); // Directly proceed to final confirmation
        }
    };

    const handleManageDishesOk = () => {
        // Redirect the user to manage their dishes
        console.log("Redirecting to manage dishes...");
        setShowManageDishesModal(false);
        // Navigate to the manage dishes page
        navigate("/settings/subscription-dish");
    };

    const handleProceedWithUnsubscribe = async () => {
        if (!listPlan) {
            toast.error("No plan selected for unsubscription.");
            return;
        }

        const { plan_id, amount, type, payment_id, validity } = listPlan;
        const data = { planId: plan_id, user_id, amount, type, validity, payment_id };

        try {
            setLoading(true);
            const unsubscribeResponse = await unsubscribePlan(data);

            if (unsubscribeResponse.status == true) {
                toast.success(unsubscribeResponse?.message);
                setShowFinalConfirmationModal(false);
                navigate("/");

            } else {
                toast.error(unsubscribeResponse?.message);

                console.error("Unsubscribe error:", unsubscribeResponse);
            }
        } catch (error) {
            console.error("Error during unsubscribe process:", error);
            toast.error("Error during unsubscribe process. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleUpgrade = () => {
        navigate("/");
    };

    return (
        <div >

            <div className="row w-100">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    {/* Plan Details with Image and Info Icon */}
                    <div className="d-flex align-items-center">
                        <img
                            src="../../../assets/images/vector.png"
                            alt="tick"
                            className="me-2"
                            style={{ width: "15px", height: "15px" }}
                        />  {/* Action (Unsubscribe/Upgrade) */}
                        <div>
                            {listPlan?.amount === 0 ? (
                                <small
                                    className="text-success"
                                    style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    }}
                                    onClick={handleUpgrade}
                                >
                                    Upgrade
                                </small>
                            ) : (
                                <small
                                    className="text-danger"
                                    style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                    }}
                                    onClick={handleShowUnsubscribeModal}
                                >
                                    Unsubscribe
                                </small>
                            )}
                        </div>
                        <span style={{ fontSize: "14px" }} className="d-flex align-items-center">
                            {/* {listPlan?.plan.name}&nbsp; */}
                            {listPlan?.amount !== 0 && (
                                <i
                                    className="material-symbols-outlined info-ico ms-2"
                                    data-bs-toggle="tooltip"
                                    title={`Name: ${listPlan?.plan.name}
Amount: $${listPlan?.amount.toFixed(2)}
Start Date: ${new Date(listPlan?.plan_start_date).toLocaleDateString("en-US", {
                                        month: "short",
                                        day: "numeric",
                                        year: "numeric",
                                    })}
End Date: ${new Date(listPlan?.plan_end_date).toLocaleDateString("en-US", {
                                        month: "short",
                                        day: "numeric",
                                        year: "numeric",
                                    })}`}
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "16px",
                                        verticalAlign: "middle",
                                    }}
                                >
                                    info
                                </i>
                            )}
                        </span>
                    </div>


                </div>
            </div>
            {/* Unsubscribe Confirmation Modal */}
            <Modal show={showUnsubscribeModal} onHide={handleCloseUnsubscribeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Unsubscribe from {listPlan?.plan.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure want to unsubscribe from {listPlan?.plan.name}?</p>
                    {listPlan?.type === 1 && (
                        <p className="text-success">
                            <span
                                role="img"
                                aria-label="alert"
                                style={{ fontSize: "24px" }}
                            >
                                ⚠️
                            </span>{" "}
                            Your subscription will end today.
                        </p>
                    )}
                    {listPlan?.type === 2 && (

                        <p className="text-success">
                            <span
                                role="img"
                                aria-label="alert"
                                style={{ fontSize: "24px" }}
                            >
                                ⚠️
                            </span>{" "}
                            Your plan will expire on  {new Date(listPlan?.plan_end_date).toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                            })}.
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-cancel rounded-5 px-5 mt-2 mb-2" onClick={handleCloseUnsubscribeModal}>
                        No
                    </Button>
                    <Button className="btn btn-primary rounded-5 px-5 mt-2 mb-2" onClick={handleConfirmUnsubscribe}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Features Loss Warning Modal */}
            <Modal show={showFeaturesModal} onHide={handleCloseUnsubscribeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>   <span role="img" aria-label="warning" style={{ fontSize: "24px" }}>
                        ⚠️
                    </span>{" "} Unsubscribe Features</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-3 mt-3">Do you want to proceed with the unsubscription?</p>
                    <Card>
                        <Card.Body>
                            <p className="text-danger">
                                Unsubscribing will immediately end your access to all features, benefits, and privileges.
                            </p>
                            {listPlan?.type === 1 && (
                                <p className="text-success">
                                    Your subscription will end today.
                                </p>
                            )}
                            {listPlan?.type === 2 && (
                                <p className="text-success">
                                    Your plan will expire on {new Date(listPlan?.plan_end_date).toLocaleDateString('en-US', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric'
                                    })}.
                                </p>
                            )}

                        </Card.Body>
                    </Card>


                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-cancel rounded-5 px-5 mt-2 mb-2" onClick={handleCloseUnsubscribeModal}>
                        Cancel
                    </Button>
                    <Button className="btn btn-primary rounded-5 px-5 mt-2 mb-2" onClick={handleProceedWithFeaturesWarning}>
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Manage Dishes Modal */}
            <Modal show={showManageDishesModal} onHide={handleCloseUnsubscribeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>   <span role="img" aria-label="warning" style={{ fontSize: "24px" }}>
                        ⚠️
                    </span>{" "}Manage Your Dishes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <p className="text-danger">
                                You currently have {listPlanDish?.totalDishCount} dishes associated with this plan.
                                Please manage your dishes before you unsubscribe.
                            </p>
                            <p className="text-warning">
                                Please note that the maximum limit for this plan is set to {listPlanDish?.planDishCount} dishes.
                            </p>
                            <p>
                                Would you like to manage your dishes now? You can proceed with that option.
                            </p>
                            <p>Or,</p>
                            <p>
                                If you wish to stay with your current plan ({listPlan?.plan.name}), you can cancel your subscription later if needed.
                            </p>
                        </Card.Body>
                    </Card>

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-cancel rounded-5 px-5 mt-2 mb-2" onClick={handleCloseUnsubscribeModal}>
                        No, Cancel
                    </Button>
                    <Button className="btn btn-primary rounded-5 px-5 mt-2 mb-2" onClick={handleManageDishesOk}>
                        Yes, Manage Dishes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Final Confirmation Modal */}
            <Modal show={showFinalConfirmationModal} onHide={handleCloseFinalConfirmationModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>  <span role="img" aria-label="warning" style={{ fontSize: "24px" }}>
                        ❗
                    </span>{" "}Final Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to unsubscribe from the plan?</p>
                    {listPlan?.type === 1 && (
                        <p className="text-success">
                            Your subscription will end today.
                        </p>
                    )}
                    {listPlan?.type === 2 && (
                        <p className="text-success">
                            Your plan will expire on {new Date(listPlan?.plan_end_date).toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                            })}.
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-cancel rounded-5 px-5 mt-2 mb-2" onClick={handleCloseFinalConfirmationModal}>
                        No
                    </Button>
                    <Button className="btn btn-primary rounded-5 px-5 mt-2 mb-2" onClick={handleProceedWithUnsubscribe}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

export default PlanUnsubscribe;

import React, { useState } from "react";
import Avatar from "react-avatar";
import { Link, useNavigate } from "react-router-dom";
import PlanUnsubscribe from "../subscription/files/PlanUnsubscribe";

interface UserProfile {
  name: string;
  profile?: string;
}

interface Plan {
  name: string;
  amount: number;
  plan_start_date: string;
  plan_end_date: string;
  plantype?: {
    planTypeName: string;
  };
}

interface HeaderProps {
  userProfile?: UserProfile | null; // Allow both undefined and null
  walletBalance: number;
  subscribedActivePlan: Plan;
}

const Header: React.FC<HeaderProps> = ({
  userProfile,
  walletBalance,
  subscribedActivePlan,
}) => {
  const navigate = useNavigate();

  console.log("*********************************************************************");
  console.log("subscribedActivePlan --header", subscribedActivePlan);
  console.log("*********************************************************************");

  const planType = subscribedActivePlan?.plantype?.planTypeName;
  const planAmount = subscribedActivePlan?.amount;
  console.log("*********************************************************************")
  console.log("planAmount", planAmount)
  console.log("*********************************************************************")

  return (
    <section className="head row top-header">
      <div className="col-12 col-md-2 d-flex align-items-center justify-content-start">
        <div className="nav-brand">
          <img
            className="img-fluid mx-auto"
            src="../../assets/images/logo.png"
            alt="Logo"
            style={{ height: "60px" }}
          />
        </div>
      </div>
      <div className="col-4 align-items-center justify-content-center">
        <h1 className="fs-4 fw-bold text-success">
          Welcome to Plan Subscription
        </h1>
      </div>
      <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
        <div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <span
            className="badge bg-primary me-5"
            style={{ fontSize: "1.0rem", padding: "10px 20px" }}
          >
            {subscribedActivePlan?.name || "No Active Plan"}
          </span>
        </div>
        {/* Avatar/Profile Dropdown */}
        <div className="dropdown profile-dd">
          <button
            className="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {userProfile?.profile ? (
              <Avatar
                src={userProfile?.profile}
                name={userProfile?.name}
                maxInitials={1}
                size="25"
                round
              />
            ) : (
              <Avatar
                name={userProfile?.name}
                maxInitials={1}
                size="25"
                round
                color="#607458"
                fgColor="#ffffff"
              />
            )}
            <p className="px-2">{userProfile?.name}</p>
          </button>
          <ul
            className="dropdown-menu header-dropdown"
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <strong>Wallet Balance:</strong> ${walletBalance?.toFixed(2) || "0.00"}
            </li>
            <li className="mt-3">
              <strong>Plan:</strong> {subscribedActivePlan?.name || "No Active Plan"}
            </li>
            {planType !== "Free Plan" && planAmount !== 0 && (
              <li className="mt-3">
                {/* Render PlanUnsubscribe only if the planTypeName is not "Free Plan" */}
                <PlanUnsubscribe />
              </li>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Header;

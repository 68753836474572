import React from "react";
import axios from "axios";
//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;

const getPlans = async (user_id: any) => {
  const response = await axios
    .post(`${API_URL}get-plan`, { user_id });
  console.log("getRatings-apii", response.data)
  return response.data;
};
const unsubscribePlan = async (data: any) => {
  console.log("*********************************************************************")
  console.log("UnsubscribePlan---apii", data)
  console.log("*********************************************************************")
  const response = await axios.post(`${API_URL}unsubscribe-plan`, data);
  return response.data;
};
const createCheckoutSession = async (data: any) => {
  console.log("*********************************************************************")
  console.log("enableDisableStatus---apii", data)
  console.log("*********************************************************************")
  const response = await axios.post(
    `${API_URL}create-checkout-session`,
    data
  );
  return response.data;
};
const handleWebhook = async (data: any) => {
  console.log("*********************************************************************")
  console.log("handleWebhook---apii", data)
  console.log("*********************************************************************")
  const response = await axios.post(
    `${API_URL}plan-webhook`,
    data
  );
  return response.data;
};

const getPaymentStatus = async (session_id: string, subscribedActivePlanId: any) => {
  console.log("Payment status API request:", { session_id, subscribedActivePlanId });

  const response = await axios.post(`${API_URL}payment-status`, {
    session_id,
    subscribedActivePlanId
  });

  console.log("Payment status API response:", response);
  return response.data;
};

const verifyStudentDetails = async (data:any) => {
  console.log("Payment status API request:", { data });
  const response = await axios.post(`${API_URL}verify-student`,  data );
  console.log("Payment status API response:", response);
  return response.data;
};

const ProfileDetails = async (id: any) => {
  console.log("*********************************************************************")
  console.log("ProfileDetails apiiii ID", id)
  console.log("*********************************************************************")
  const response = await axios.post(`${API_URL}view-user/${id}`);
  return response.data;
};
export {
  getPlans,
  unsubscribePlan,
  createCheckoutSession,
  handleWebhook,
  getPaymentStatus,
  ProfileDetails,
  verifyStudentDetails

};
